import { Controller } from '@hotwired/stimulus'

const optionsMap = {
  tags: {
    create: true,
    render: {
      option_create: (data, escape) =>
        '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>',
    },
  },
  media: {
    labelField: 'name',
    valueField: 'id',
    searchField: ['name', 'id'],
    preload: true,
    render: {
      item: (item) => '<img src="/media_objects/' + item.id + '" />',
      option: (item) => '<img src="/media_objects/' + item.id + '" />',
    },
  },
}

export class SelectizeController extends Controller {
  static targets = ['input']

  connect() {
    const options = optionsMap[this.inputTarget.dataset.type || '']
    $(this.inputTarget).selectize(options)
    this.instance = this.inputTarget.selectize

    if (this.inputTarget.dataset.fetch) {
      let url = this.inputTarget.dataset.fetch
      this.runningTimeout = null
      this.isDataStale = true

      this.instance.onSearchChange = (_query) => {
        if (!this.isDataStale) return

        // Fetch data => not stale
        this.fetchData(url)
        this.isDataStale = false

        if (this.runningTimeout) clearTimeout(this.runningTimeout)
        // Data is stale after 5 seconds
        this.runningTimeout = setTimeout(() => {
          this.isDataStale = true
          this.runningTimeout = null
        }, 5000)
      }
    }
  }

  disconnect() {
    try {
      this.instance?.destroy()
    } catch {}
  }

  async fetchData(url) {
    const response = await fetch(url)
    const data = await response.json()
    this.instance.load((callback) => callback(data.map((e) => ({ value: e, text: e }))))
  }
}
