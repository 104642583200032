import { Application } from '@hotwired/stimulus'
import { AccountmailerController } from './accountmailer_controller'
import { ChartController } from './chart_controller'
import { PasswordgenController } from './passwordgen_controller'
import { RevealController } from './reveal_controller'
import { SelectizeController } from './selectize_controller'
import { TextautogrowController } from './textautogrow_controller'

window.Stimulus = Application.start()

Stimulus.register('accountmailer', AccountmailerController)
Stimulus.register('chart', ChartController)
Stimulus.register('passwordgen', PasswordgenController)
Stimulus.register('reveal', RevealController)
Stimulus.register('selectize', SelectizeController)
Stimulus.register('textautogrow', TextautogrowController)
