import { Controller } from '@hotwired/stimulus'

export class PasswordgenController extends Controller {
  static targets = ['pw']

  // Copy the generated password
  copy(e) {
    e.preventDefault()
    this.pwTargets[0].select()
    document.execCommand('copy')
  }

  // Generate a new password
  generate(e) {
    e.preventDefault()

    // generate password
    const length = 6 + Math.round(Math.random() * 6)
    const password = this._generatePassword(length)

    // set value
    this.pwTargets.forEach((element) => {
      element.value = password
      element.dispatchEvent(new Event('input'))
    })

    // make passwords visible
    this.pwTargets.forEach((element) => element.setAttribute('type', 'text'))
  }

  _generatePassword(length = 8) {
    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let retVal = ''
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n))
    }
    return retVal
  }
}
