import { Controller } from '@hotwired/stimulus'

export class AccountmailerController extends Controller {
  static targets = ['emailfields', 'delivermailcheck', 'addontext']

  connect() {
    const pwField = document.querySelector('#user_password')

    if (pwField) {
      pwField.addEventListener('input', () => {
        if (pwField.value === '') {
          if (this.hasDelivermailcheckTarget) this.delivermailcheckTarget.checked = false
          this.emailfieldsTarget.classList.add('d-none')
        } else this.emailfieldsTarget.classList.remove('d-none')
      })
    }

    // store text in local storage if addontext is present
    if (this.hasAddontextTarget) {
      // store text on change
      this.addontextTarget.addEventListener('change', () => {
        localStorage.setItem('accountmailer_addontext', this.addontextTarget.value)
      })

      // restore text initially
      this.addontextTarget.value = localStorage.getItem('accountmailer_addontext') || ''
    }
  }
}
