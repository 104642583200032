import { Controller } from '@hotwired/stimulus'

function debounce(callback, delay) {
  let timeout

  return (...args) => {
    const context = this
    clearTimeout(timeout)

    timeout = setTimeout(() => callback.apply(context, args), delay)
  }
}

export class TextautogrowController extends Controller {
  // @ts-ignore
  // element: HTMLInputElement
  // onResize: EventListenerOrEventListenerObject // eslint-disable-line no-undef

  // resizeDebounceDelayValue: number

  // static values = {
  //   resizeDebounceDelay: {
  //     type: Number,
  //     default: 100,
  //   },
  // }

  initialize() {
    this.autogrow = this.autogrow.bind(this)
  }

  connect() {
    this.element.style.overflow = 'hidden'
    const delay = this.resizeDebounceDelayValue

    this.onResize = delay > 0 ? debounce(this.autogrow, delay) : this.autogrow

    this.autogrow()

    this.element.addEventListener('input', this.autogrow)
    window.addEventListener('resize', this.onResize)
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize)
  }

  autogrow() {
    this.element.style.height = 'auto' // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${this.element.scrollHeight}px`
  }
}
