import * as Turbo from '@hotwired/turbo'

import '@rails/actiontext'
import '@rails/ujs'
import 'trix'

import './controllers'

Turbo.start()

$(document).on('ready turbo:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

$(document).on('ready turbo:load', () => {
  // Hide modal content and show spinner instead when statsModal is hidden. Hence, on the
  // next show of statsModal the spinner is visible instead of the old data.
  $('#statsModal').on('hidden.bs.modal', function (event) {
    $('#statsModalContent').hide()
    $('#statsModalSpinner').show()
  })

  // allow only one popover to be open at once
  $('html').on('mouseup', function (e) {
    var l = $(e.target)
    if (l[0].className.indexOf('popover') == -1) {
      $('.popover').each(function () {
        $(this).popover('hide')
      })
    }
  })
})
