import { Controller } from '@hotwired/stimulus'
import { LineChart } from 'chartist'

export class ChartController extends Controller {
  static targets = ['display']

  connect() {
    const dataUrl = this.displayTarget.dataset['url']
    fetch(dataUrl, { headers: { 'Content-Type': 'application/json' } })
      .then((resp) => resp.json())
      .then((data) => this.drawLineChart(data))
  }

  drawLineChart(data) {
    const height = this.displayTarget.dataset['height'] || '200px'
    new LineChart(this.displayTarget, data, {
      height,
      showArea: true,
      axisX: {
        labelInterpolationFnc: function skipLabels(value, index, chart) {
          let divisor = Math.round(chart.length / 10)
          return index % divisor === 0 ? value : null
        },
      },
    })
  }
}
